import React, { useState, useEffect } from "react";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import Layout from "../../layout";
import LayoutDashboard from "../layout";

const DashboardEditProfile = () => {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [current_password, setCurrentPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/user/users/me`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || "Failed to fetch user profile");
        }

        const userData = await response.json();
        setFirstName(userData.first_name);
        setLastName(userData.last_name);
        setEmail(userData.email);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchUserProfile();
  }, []); // Empty dependency array ensures this runs once when the component mounts

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(""); // Clear previous errors
    setSuccess(""); // Clear previous success messages

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/user/update-profile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
          first_name,
          last_name,
          email,
          current_password,
          new_password,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to update profile");
      }

      setSuccess("Profile updated successfully!");
    } catch (error) {
      setError(error.message); // Set the error message to display
    }
  };

  return (
    <Layout>
      <LayoutDashboard>
        <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
          <form className="grid md:grid-cols-2 gap-6" onSubmit={handleSubmit}>
            {error && <span className="text-red-500 md:col-span-2">{error}</span>}
            {success && <span className="text-green-500 md:col-span-2">{success}</span>}
            <label className="block">
              <Label>First name</Label>
              <Input
                placeholder="Example Doe"
                type="text"
                className="mt-1"
                value={first_name}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </label>
            <label className="block">
              <Label>Last name</Label>
              <Input
                placeholder="Doe"
                type="text"
                className="mt-1"
                value={last_name}
                onChange={(e) => setLastName(e.target.value)}
              />
            </label>
            <label className="block">
              <Label>Current password</Label>
              <Input
                placeholder="***"
                type="password"
                className="mt-1"
                value={current_password}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </label>
            <label className="block">
              <Label>New password</Label>
              <Input
                type="password"
                className="mt-1"
                value={new_password}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </label>
            <label className="block md:col-span-2">
              <Label>Email address</Label>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <ButtonPrimary className="md:col-span-2" type="submit">
              Update profile
            </ButtonPrimary>
          </form>
        </div>
      </LayoutDashboard>
    </Layout>
  );
};

export default DashboardEditProfile;
