import React, { useState, useEffect } from "react";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Select from "components/Select/Select";
import Label from "components/Label/Label";
import Layout from "../../layout";
import LayoutDashboard from "../layout";

const DashboardBillingAddress = () => {
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Fetch existing billing address (if applicable)
  useEffect(() => {
    const fetchBillingAddress = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/user/billing-address`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch billing address");
        }

        const data = await response.json();
        setCountry(data.country);
        setState(data.state);
        setAddressLine1(data.addressLine1);
        setAddressLine2(data.addressLine2);
        setCity(data.city);
        setPostalCode(data.postalCode);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchBillingAddress();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(""); // Clear previous errors
    setSuccess(""); // Clear previous success messages

    // Simple validation
    if (!country || !state || !addressLine1 || !city || !postalCode) {
      setError("Please fill in all required fields.");
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/user/billing-address`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
          country,
          state,
          addressLine1,
          addressLine2,
          city,
          postalCode,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to update billing address");
      }

      setSuccess("Billing address updated successfully!");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Layout>
      <LayoutDashboard>
        <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
          <form className="grid md:grid-cols-2 gap-6" onSubmit={handleSubmit}>
            {error && <span className="text-red-500 md:col-span-2">{error}</span>}
            {success && <span className="text-green-500 md:col-span-2">{success}</span>}
            <label className="block">
              <Label>Country</Label>
              <Select className="mt-1" value={country} onChange={(e) => setCountry(e.target.value)}>
                <option value="">Select a country</option>
                <option>United States</option>
                <option>Canada</option>
                <option>Mexico</option>
                <option>VietNam</option>
                <option>Japan</option>
              </Select>
            </label>
            <label className="block">
              <Label>State/Province/Region *</Label>
              <Select className="mt-1" value={state} onChange={(e) => setState(e.target.value)}>
                <option value="">Select a state</option>
                <option value="ha'apai">{`Ha'apai`}</option>
                <option value="tongatapu">Tongatapu</option>
                <option value="vava'u">{`Vava'u`}</option>
              </Select>
            </label>
            <label className="block">
              <Label>Address Line 1 *</Label>
              <Input
                type="text"
                className="mt-1"
                value={addressLine1}
                onChange={(e) => setAddressLine1(e.target.value)}
              />
            </label>
            <label className="block">
              <Label>Address Line 2</Label>
              <Input
                type="text"
                className="mt-1"
                value={addressLine2}
                onChange={(e) => setAddressLine2(e.target.value)}
              />
            </label>
            <label className="block">
              <Label>City *</Label>
              <Input
                type="text"
                className="mt-1"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </label>
            <label className="block">
              <Label>Postal/ZIP Code *</Label>
              <Input
                type="text"
                className="mt-1"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </label>
            <ButtonPrimary className="md:col-span-2" type="submit">
              Update Billing Address
            </ButtonPrimary>
          </form>
        </div>
      </LayoutDashboard>
    </Layout>
  );
};

export default DashboardBillingAddress;
