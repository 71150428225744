import React, { useState } from "react";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Select from "components/Select/Select";
import Textarea from "components/Textarea/Textarea";
import Label from "components/Label/Label";
import Layout from "../../layout";
import LayoutDashboard from "../layout";

const DashboardSubmitPost = () => {
  const [title, setTitle] = useState("");
  const [description, setExcerpt] = useState("");
  const [category, setCategory] = useState("-1");
  const [tags, setTags] = useState([]);
  const [content, setContent] = useState("");
  const [image, setImage] = useState<null | String | BinaryData>(null);

  const handleFileUpload = (event) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const binaryString = reader.result;
      setImage(binaryString);
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();



    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/blogs/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
          title,
          description,
          category,
          tags,
          content,
          image,
        }),
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const data = await response.json();
      console.log("Post submitted successfully:", data);
      // Optionally reset the form or provide user feedback
    } catch (error) {
      console.error("There was an error submitting the post:", error);
    }
  };

  return (
    <Layout>
      <LayoutDashboard>
        <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
          
          <form className="grid md:grid-cols-2 gap-6" onSubmit={handleSubmit}>
            <label className="block md:col-span-2">
              <Label>Post Title *</Label>
              <Input
                type="text"
                className="mt-1"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </label>
            <label className="block md:col-span-2">
              <Label>Post Description</Label>
              <Textarea
                className="mt-1"
                rows={4}
                value={description}
                onChange={(e) => setExcerpt(e.target.value)}
              />
              <p className="mt-1 text-sm text-neutral-500">
                Brief description for your article. URLs are hyperlinked.
              </p>
            </label>
            <label className="block">
              <Label>Category</Label>
              <Select
                className="mt-1"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="-1">– select –</option>
                <option value="ha'apai">Category 1</option>
                <option value="tongatapu">Category 2</option>
                <option value="vava'u">Category 3</option>
              </Select>
            </label>
            <label className="block md:col-span-2">
                <Label>Tags</Label>
                <Input
                type="text"
                className="mt-1"
                value={Array.isArray(tags) ? tags.join(", ") : ""}
                onChange={(e) => setTags(e.target.value.split(",").map(tag => tag.trim()))}
                />
              <p className="mt-1 text-sm text-neutral-500">
              Separate tags with commas.
              </p>
            </label>
            <div className="block md:col-span-2">
              <Label>Featured Image</Label>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-700 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-neutral-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <div className="flex flex-col sm:flex-row text-sm text-neutral-6000">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                    >
                      <span>Upload a file</span>
                      <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      onChange={handleFileUpload}
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-neutral-500">
                    PNG, JPG, GIF up to 2MB
                  </p>
                </div>
              </div>
            </div>
            <label className="block md:col-span-2">
              <Label>Post Content</Label>
              <Textarea
                className="mt-1"
                rows={16}
                value={content}
                onChange={(e) => setContent(e.target.value)}
                required
              />
            </label>

            <ButtonPrimary className="md:col-span-2" type="submit">
              Submit post
            </ButtonPrimary>
          </form>
        </div>
      </LayoutDashboard>
    </Layout>
  );
};

export default DashboardSubmitPost;
