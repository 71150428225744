import React, { FC } from "react";
import { useSelector } from "react-redux"; // Import useSelector
import MainNav2Logged from "./MainNav2Logged"; // Assuming this is for logged-in users
import MainNav1 from "./MainNav1"; // Assuming this is for non-logged-in users
import { RootState } from "../../redux/store"; // Import RootState for type safety

export interface HeaderLoggedProps {}

const HeaderLogged: FC<HeaderLoggedProps> = () => {
  // Access the logged-in state from Redux
  // const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  // const isLoggedIn = useSelector((state: auth) => state.isAuthenticated);
  const isLoggedIn = useSelector((state: RootState) => state.auth.isAuthenticated);

  return (
    <div className="nc-HeaderLogged sticky top-0 w-full z-40">
      {isLoggedIn ? <MainNav2Logged /> : <MainNav1 />}
    </div>
  );
};

export default HeaderLogged;
