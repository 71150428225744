// import { createStore } from 'redux';
// import rootReducer from './reducer';
import { configureStore } from '@reduxjs/toolkit';
import authSlice from './auth/authSlice';

// Create the Redux store
export const store = configureStore({
    reducer: {
        auth: authSlice,
        // : rootReducer,
    }
    });

// Define RootState type
export type RootState = ReturnType<typeof store.getState>;


export default store;
