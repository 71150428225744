import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Layout from "../layout";
import { logout } from "../../../redux/auth/authSlice"; // Import the logout action

const PageLogout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    // Remove the access token
    localStorage.removeItem("access_token");

    // Dispatch the logout action
    dispatch(logout()); // Update the Redux state to logged out

    navigate("/login"); // Redirect to the login page
  };

  return (
    <Layout>
      <header className="text-center max-w-2xl mx-auto -mb-14 sm:mb-16 lg:mb-20">
        <h2>Logout</h2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          You are about to log out from the community.
        </span>
      </header>

      <div className="max-w-md mx-auto space-y-6 text-center">
        <p>Are you sure you want to logout?</p>
        <button 
          onClick={handleLogout} 
          className="px-4 py-2 bg-red-600 text-white rounded"
        >
          Logout
        </button>
      </div>
    </Layout>
  );
};

export default PageLogout;
