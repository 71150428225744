// Define the fetchAuthors function with authorization
const fetchAuthors = async (): Promise<any[]> => {
    try {
        const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/author/authors`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer YOUR_TOKEN_HERE', // Replace with your actual token
          'Content-Type': 'application/json',
        },
      });
  
      // Check if the response is ok (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const authors: any[] = await response.json();
      return authors; // Return the fetched authors
    } catch (error) {
      console.error('Error fetching authors:', error);
      return []; // Return an empty array on error
    }
  };
  
  // Export the fetchAuthors function as a variable
  export { fetchAuthors };
  
  // Example usage (optional; can be removed when using this in a module)
  fetchAuthors().then(authors => {
    console.log(authors);
  });
  