import React from "react";
import SectionHero from "components/SectionHero/SectionHero";
import rightImg from "images/about-hero-right.png";
import SectionFounder from "./SectionFounder";
// import SectionStatistic from "./SectionStatistic";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";

const PageAbout = () => {
  return (
    <div className={`nc-PageAbout relative`}>
      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="Welcome to KnowNow, where your business success story begins. We are not just a company; we are part of your dreams, your success, and your business growth. Here, you will find every service you need to take your business to the next level."
        />

<SectionFounder />
        <SectionHero
          heading="👁️ Our Vision."
          btnText=""
          subHeading="At KnowNow, our vision is to create a world where your business is empowered with seamless and efficient solutions. Every challenge is an opportunity, and we have innovative solutions for every problem. Your growth is our mission."
        />
        <SectionHero
          heading="🚀 Our Journey."
          btnText=""
          subHeading="Our journey began with website designing, but we never imagined we could come this far. With hard work and dedication, we have established ourselves in multiple fields. With every project, we strive to earn your trust, and with your support, we aim to move forward."
        />

        <SectionHero
          heading="🤝 Our Commitment."
          btnText=""
          subHeading="Our commitment is not just to provide services, but to be a reliable partner in your success. We are dedicated to ensuring your business growth, and our team is with you at every step. Our mission is to achieve your goals and make your business journey smooth."
        />

        <SectionHero
          heading="➕ Join Us."
          btnText=""
          subHeading="Join KnowNow and see how we can redefine your business. Welcome to KnowNow – where every service opens the gateway to a new success story."
        />

        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionStatistic />
        </div> */}

        {/* <SectionSubscribe2 /> */}
      </div>
    </div>
  );
};

export default PageAbout;
